import React, { useCallback, useEffect, useState } from "react";
import {CookiesProvider, useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TopNav } from "./TopNav";
import { LeftNav } from "./LeftNav";
import Loader from "../components/Loader/loader";


const Portal = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  }, [setIsLoading]);

  useEffect(() => {
    if (!Cookies.get("id")) {
      return navigate("/");
    }
  }, [navigate]);

  
  return ( 
    <div className="wrapper bg-slate-100 h-screen">
      <LeftNav />
      {isLoading ? (
        <div className="main dashBoardLayout">
          <TopNav />
          <main className="content ">
            <div className="container-fluid">
              <div className="row pt-11 text-xs px-4">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="w-screen h-screen justify-center items-center">
       <Loader size="medium" />
       </div>
      )}
    </div>
   
  );
};

export { Portal };
