function convertToUSFormat(date: any) {
    const dateTime = new Date(date);
  
    // Check if the date is valid
    if (isNaN(dateTime.getTime())) {
      return "Invalid Date";
    }
  
    // Format the date in US format
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    const usFormat = dateTime.toLocaleDateString('en-US', options);
  
    return usFormat;
  }
export {convertToUSFormat}