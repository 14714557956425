import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getMembersList, getProjects } from "../../api/api.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.min.css";
import { Button } from "../../components/Button/button";
import { AddTeamMember } from "./Add";
import defaultPic from "../../assets/defaultWorkspaceImg.png";
import { useTeamList } from "../../data/data";
import { useMemberDetails } from "../../context/AuthContext";


const AddMembers = () => {
  const [teamList, setTeamList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const { memberDetails, error } = useMemberDetails();
  const orgID =
    user_type === "admin"
      ? memberDetails && memberDetails[0]?.id
      : memberDetails && memberDetails[0]?.org_id;
  const { data, isLoading, isFetching, refetch } = useTeamList(
    "getMembersList",
    orgID
  );

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      cellRenderer: (p: any) => {
        return (
          <div className="flex leading-20">
            {p.data.profile_pic ? (
              <>
                <img
                  className="my-2 w-5 h-5 rounded-full me-2"
                  src={p.data.profile_pic?.toString()}
                  alt={p.data.name}
                />
                <span className="capitalize" title="">
                  {p?.data?.name}
                </span>
              </>
            ) : (
              <>
                <img
                  className="my-2 w-5 me-2"
                  src={defaultPic}
                  alt={p.data.name}
                />
                <span className="capitalize" title="">
                  {p?.data?.name}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Designation",
      field: "designation",
      flex: 1,
      cellRenderer: (p: any) => {
        return (
          <span
            className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
            title=""
          >
            {p?.data?.designation}
          </span>
        );
      },
    },
    

    {
      headerName: "Status",
      field: "status",
      flex: 1,
      cellRenderer: (p: any) => {
        return p.data.status === "0" ? (
          <span
            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-gray-500/10"
            title=""
          >
            Active
          </span>
        ) : (
          <span
            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-gray-500/10"
            title=""
          >
            Inactive
          </span>
        );
      },
    },

    {
      headerName: "Actions",
      field: "",
      flex: 1,
      cellRenderer: (p: any) => {
        const id = p.data.user_id;
        return (
          <>
            <i onClick={() => handleEdit(id)} className="fa-regular fa-pen-to-square"></i>
            <i className="fa-solid fa-trash ms-2"></i>
          </>
        );
      },
    },
  ];

  function handleEdit(id: any) {
    setIsDialogOpen(true);
    setSelectedID(id);
    
  }
  console.log(selectedID);

  function handleAdd() {
    setIsDialogOpen(true);
  }

  const handleChildData = (data: any) => {
    setIsDialogOpen((current) => !current);
    setSelectedID(null);
    refetch()
  };

  return (
    <div>
      <div className="flex justify-between my-3">
        <h2 className="text-lg capitalize">
          <span className="font-bold text-xl">Members</span>
        </h2>
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-md bg-indigo-600 px-3  h-8 font-semibold  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleAdd}
          children={"Add"}
        />
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 500,
          zIndex: 0,
          fontFamily: "ui-sans-serif, system-ui, sans-serif",
          fontSize: "12px",
        }}
      >
        {isFetching ? (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-indigo-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : data?.length ? (
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data || []}
            rowHeight={35}
            pagination={true}
            paginationPageSize={20}
          />
        ) : (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            No data Available
          </div>
        )}
      </div>
      {isDialogOpen ? (
        <AddTeamMember
          isDialogOpen={isDialogOpen}
          closeDialog={handleChildData}
          selectedID={selectedID}
        />
      ) : null}
    </div>
  );
};

export { AddMembers };
