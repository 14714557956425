import React, { useState, createContext, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import "./App.scss";
import { Portal } from "./screens/portal.tsx";
import Login from "./screens/Login";
import { DashBoard } from "./screens/DashBoard";
import { AddTask } from "./modules/addTask";
import { Home } from "./screens/Home.tsx";
import { SignUp } from "./screens/SignUp.tsx";
import { WorkPlace } from "./modules/WorkPlace.tsx";
import { UnauthorizedPage } from "./modules/UnauthorizedPage.tsx";
import { AddMembers } from "./modules/team/List";
import { Projects } from "./modules/projects/Projects";
import { OnBoarding } from "./modules/OnBoarding";
import { TaskDetails } from "./modules/taskDetails";
import { TaskList } from "./modules/taskList/taskList";
import { ListSprints } from "./modules/sprint";
import { ListFixVersions } from "./modules/fixVersion";

export const store = createContext();
function App() {
  const [id, setId] = useState(null);
  const [role, setRole] = useState(null);
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <store.Provider value={[id, setId, role, setRole]}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="*" element={<UnauthorizedPage />} />
          <Route path="portal" element={<Portal />}>
            <Route path="dashboard" element={<DashBoard />}></Route>
            <Route path="tasklist" element={<TaskList />}></Route>
            <Route path="onboarding" element={<OnBoarding />}></Route>
            <Route path="workplace" element={<WorkPlace />}></Route>
            <Route path="team" element={<AddMembers />}></Route>
            <Route path="projects" element={<Projects />}></Route>
            <Route path="addtask" element={<AddTask />}></Route>
            <Route path="sprints" element={<ListSprints />}></Route>
            <Route path="fixversions" element={<ListFixVersions />}></Route>
            <Route
              path="taskDetails/:task_id"
              element={<TaskDetails />}
            ></Route>
          </Route>
        </Routes>
      </store.Provider>
    </QueryClientProvider>
  );
}

export default App;
