import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AddTask } from "../modules/addTask";
import { Dialog } from "../components/Dialog/dialog";
import { getProjects } from "../api/api";
import { useMemberDetails } from "../context/AuthContext";

//import { useCookies } from "react-cookie";

const LeftNav = (onToggle: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [onboardDetails, setOnboardDetails] = useState<any>([]);
  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");

  const { memberDetails, error } = useMemberDetails();

  console.log("memberDetails", memberDetails);
  function handleDialog() {
    setOpenDialog((current) => !current);
  }

  const handleCloseDialog = (e: any) => {
    setOpenDialog(e);
  };

  return (
    <aside
      className="fixed top-0 left-0 z-40 w-40 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 overflow-y-auto bg-white">
        <div className="flex items-center">
          {memberDetails ? (
            <>
              <img
                className="my-2 ms-3 w-10"
                src={memberDetails?.map((data: any) => {
                  return data.logo.toString();
                })}
                alt={""}
              />
              <h2 className="font-bold mx-2">{memberDetails[0]?.name}</h2>
            </>
          ) : (
            <>
              <img
                className="w-10 my-2 rounded"
                src={require("../assets/defaultWorkspaceImg.png")}
              />
              <h2>Task Management</h2>
            </>
          )}
        </div>

        <button onClick={handleDialog} className="newTaskSmall ms-3 my-4">
          + New Task
        </button>

        {user_type === "admin" ? (
          <ul className="space-y-2 text-sm mt-4 leftNav">
            <li className="leading-8">
              <NavLink to="/portal/dashboard" className="ms-1 text-sm">
                <i className="fa-solid fa-chart-line mx-2"></i> Dashboard
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/tasklist" className="ms-1 text-sm">
                <i className="fa-solid fa-tasks mx-2"></i> Task List
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/team" className="ms-1 text-sm">
                <i className="fa-solid fa-user mx-2"></i> Team
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/projects" className="ms-1 text-sm">
                <i className="fa-solid fa-briefcase mx-2"></i> Projects
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/sprints" className="ms-1 text-sm">
                <i className="fa-solid fa-tachometer-alt mx-2"></i> Sprint
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/fixversions" className="ms-1 text-sm">
                <i className="fa-solid fa-wrench mx-2"></i> Fix Version
              </NavLink>
            </li>
            <li className="leading-8">
              <NavLink to="/portal/onboarding" className="ms-1 text-sm">
                <i className="fa-solid fa-gear mx-2"></i> Settings
              </NavLink>
            </li>
          </ul>
        ) : null}
      </div>
      {openDialog ? <AddTask closeDialog={handleCloseDialog} /> : null}
    </aside>
  );
};

export { LeftNav };
