import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getProjects } from "../api/api.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.min.css";
import { Button } from "../components/Button/button";

const WorkPlace = () => {
  const [onboardDetails, setOnboardDetails] = useState<any>([]);
  const user_id = Cookies.get("id");
  const userRole = Cookies.get("role");
  async function getLoginUserDetails() {
    try {
      const action = "getOnboardDetails";
      const data = await getProjects(action, user_id);
      setOnboardDetails(data);
    } catch (error: any) {
      console.error("Error during login process:", error.message);
    }
  }
  useEffect(() => {
    getLoginUserDetails();
  }, [user_id]);

  const columnDefs = [
    { headerName: "Company Name", field: "companyName", flex: 1 },
    { headerName: "Team Size", field: "teamSize", flex: 1 },
    {
      headerName: "Logo",
      field: "companyLogo",
      flex: 1,
      cellRenderer: (p: any) => {
        console.log("company", p)
        return    <img
        className="my-2 w-10"
        src={p.data.companyLogo?.toString()}
        alt="Your Company"
      />;
      },
    },
    {
        headerName: "Actions",
        field: "",
        flex: 1,
        cellRenderer: (p: any) => {
          console.log("company", p)
          return  <>
          <i className="fa-regular fa-pen-to-square"></i>
          <i className="fa-solid fa-trash ms-2"></i>
          </>
        },
      },
  ];

  function handleAdd(){

  }
  return <div>
       <div className="flex justify-between my-3">
        <h2 className="text-lg capitalize">
          <span className="font-bold text-xl">Members</span>
        </h2>
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-md bg-indigo-600 px-3   font-semibold  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleAdd}
          children={"Add"}
        />
      </div>
     <div
        className="ag-theme-alpine"
        style={{
          height: 500,
          zIndex: 0,
          fontFamily: "ui-sans-serif, system-ui, sans-serif",
          fontSize: "12px",
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={onboardDetails || []}
          rowHeight={35}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
  </div>;
};

export { WorkPlace };
