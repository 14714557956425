import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getDataDetails, getProjects } from "../../api/api";
import { Button } from "../../components/Button/button";
import _ from "lodash";
import { useMemberDetails } from "../../context/AuthContext";


type createProps = {
  closeDialog: any;
  isDialogOpen: boolean;
  selectedID: any
};

const AddProject = ({ closeDialog, isDialogOpen, selectedID }: createProps) => {
  const [openDialog, setOpenDialog] = useState(true);
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [projectsList, setProjectsList] = useState([]);
  const [editDetails, setEditDetails] = useState([]);
  const user_id: any = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const [disabled, setDisabled] = useState(true)
  const { memberDetails, error } = useMemberDetails();
  const [onBoard, setOnBoard] = useState<any>({
    name: "",
  });

  const orgID =
  user_type === "admin"
    ? memberDetails && memberDetails[0]?.id
    : memberDetails && memberDetails[0]?.org_id;

  useEffect(() => {

    async function getOrganizationData() {
      try {
        const action = "getProjectDetails";
        const data = await getDataDetails(action, user_id, selectedID);
        setEditDetails(data);
             // If organization details are available, populate the form fields
             if (data.length) {
              const { name, teamSize, logo } = data[0];
              setOnBoard({
                name: name || "",
              });
            }
      } catch (error: any) {
        console.error("Error during login process:", error.message);
      }
    }
  
    getOrganizationData(); // Call the async function
  }, [user_id, selectedID]);

console.log("selectedID", selectedID)
  const handleAddOnboard = async (e: any) => {
    e.preventDefault();
    setIsbtnLoad(true);
    try {
      const response = await fetch("https://makeithost.com/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: selectedID ? "editProject" : "addProject",
          name: onBoard.name,
          admin_id: parseInt(user_id),
          org_id: parseInt(orgID),
          id: parseInt(selectedID),
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setIsbtnLoad(false);
        setTimeout(() => {
          sendDataToParent();
          console.log("a");
        }, 6000);
      }
      const result = await response.json();
      setErrorValidation(result.result);
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  const sendDataToParent = () => {
    const data = "Hello from the Child!";
    closeDialog(data);
  };

useEffect(() => {
  onBoard.name.length ? setDisabled(false) : setDisabled(true);
}, [onBoard.name])


  return (
    <>
      {isDialogOpen ? (
        <div
          style={{ backgroundColor: "#00000073" }}
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
        >
          <div className="relative p-4 w-4/12 max-h-full">
            <div className="relative bg-white rounded-lg shadow p-4">
              <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
                  Add Project
                </h3>
                <i
                  className="fa-solid fa-close mx-2 cursor-pointer"
                  onClick={closeDialog}
                ></i>
              </div>
              {errorValidation ? (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">{errorValidation}</strong>
                </div>
              ) : null}

              <div className=" py-4 px-10">
                <div className="my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
                  >
                    Name<small className="text-rose-400 mb-2">*</small>
                  </label>
                  <div className="mt-0 w-12/12">
                    <input
                      type="text"
                      value={onBoard.name}
                      onChange={(e) =>
                        setOnBoard({ ...onBoard, name: e.target.value })
                      }
                      className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <footer className="border-t mt-2 pt-3 flex justify-end dark:border-gray-100 px-6">
                <button onClick={closeDialog} className="mx-4 text-sky-700">
                  Cancel
                </button>
                <Button
                  children={selectedID ? "Update" : "Save"}
                  type="button"
                  className="items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleAddOnboard}
                  isButtonLoaded={isbtnLoad}
                  disabled={disabled}
                />
              </footer>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { AddProject };
