// Login.js
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Button } from "../components/Button/button.tsx";
import  axios  from "axios";
import {BASE_URL} from '../api/api.js'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [errorValidation, setErrorValidation] = useState("");
  const [validationMsg, setValidationMsg] = useState(null)
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsbtnLoad(true);
    axios
    .post(`${BASE_URL}`, {
      action: "login",
      email,
      password,
    })
    .then((response) => {
      Cookies.set("id", `${response?.data[0]?.user_id}`, {
        expires: 7,
        path: "/",
      });
      Cookies.set("user_type", `${response?.data[0]?.user_type}`, {
        expires: 7,
        path: "/",
      });
      console.log("response", response)
      return response?.data[0]?.user_id !== null && response?.data[0]?.user_id !== undefined ?  navigate("/portal/dashboard")
       : (
        setValidationMsg(response?.data[0]?.result),
        setIsbtnLoad(false)
        )
    })
    .catch((error) => {
      console.log("error: " + error);
    });
  };

  useEffect(() => {
    console.log("Cookies", Cookies);
  }, [Cookies])
  
console.log("validationMsg", validationMsg)
  return (
    <div className="flex h-screen justify-center flex-col  lg:px-8 items-center bg-slate-100">
      <div className="w-96 px-4 bg-white rounded-md py-4">
        <div className="">
          <img
            className="my-2 mx-auto h-10 w-20"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <div className="my-6 px-4">
          <form className="space-y-6" action="#" method="POST">
            <div className="text-left ">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 w-full"
              >
                Email address
              </label>

              <div className="mt-2 w-full">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {_.includes(errorValidation, "Error") ? (
                <small className="text-rose-700">Invalid Credentials </small>
              ) : null}
              {
                <small className="text-rose-700">{validationMsg} </small> 
              }
            </div>

            <div>
              <Button
                type={"submit"}
                isButtonLoaded={isbtnLoad}
                className={
                  "items-center flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={handleLogin}
                children={"Sign in"}
              />
            </div>
          </form>
          <p className="text-center px-6 w-full text-xs text-slate-400 mt-4">Don't have account?
          <Link
              to="/signup"
              className="px-1 text-indigo-500"
            >
              Sign Up for free
            </Link>
           </p>

          {/* <p className="my-4 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="#"
              className="mx-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Start a 14 day free trial
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
