import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { BASE_URL } from "../api/api.js";
import Cookies from "js-cookie";

interface MemberDetails {
  // Define the structure of your member details here
}

interface MemberDetailsContextType {
  memberDetails: any | null;
  error: string | null;
}

const MemberDetailsContext = createContext<
  MemberDetailsContextType | undefined
>(undefined);

export const MemberDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [memberDetails, setMemberDetails] = useState<MemberDetails | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");

  useEffect(() => {
    const fetchMemberDetails = async () => {
      if (!user_id || !user_type) {
        setError("User ID or user type not found in cookies");
        return;
      }

      const action = "getOrgId";

      try {
        const response = await fetch(`${BASE_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: user_id, user_type, action }),
        });

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }

        const data = await response.json();
        setMemberDetails(data);
      } catch (error) {
        if (error instanceof Error) {
          setError(`Error fetching member details: ${error.message}`);
        } else {
          setError("An unknown error occurred");
        }
      }
    };

    if (user_id && (user_type === "admin" || user_type === "member")) {
      fetchMemberDetails();
    } else {
      setError("Invalid user ID or user type");
    }
  }, [user_id, user_type]);

  const contextValue = {
    memberDetails,
    error,
  };

  return (
    <MemberDetailsContext.Provider value={contextValue}>
      {children}
    </MemberDetailsContext.Provider>
  );
};

export const useMemberDetails = () => {
  const context = useContext(MemberDetailsContext);
  if (!context) {
    throw new Error(
      "useMemberDetails must be used within a MemberDetailsProvider"
    );
  }
  return context;
};
