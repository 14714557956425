import React from "react";
import Loader from "../Loader/loader";

type buttonProps = {
  children: string;
  type: any;
  className: string;
  isButtonLoaded?: boolean;
  onClick: (e: any) => void;
  disabled?: boolean
};

const Button = ({
  children,
  type,
  className,
  isButtonLoaded,
  onClick,
  disabled,
}: buttonProps) => {
  const updatedClassName = disabled ? `${className} disabled opacity-50` : className;

  return (
    <button type={type} onClick={onClick} className={updatedClassName} disabled={disabled}>
      {children}
      {isButtonLoaded ? <Loader size="small" /> : null}
    </button>
  );
};

export { Button };
