import React from "react";
import { Link } from "react-router-dom";

const UnauthorizedPage = () => {
  const imgURL = "./assets/images/pagenotFound.jpg";
  return (
    <div className="w-100">
      <div className="row w-100  py-6 px-0 text-center h-100">
        <h1 className="tradeForm position-relative">Page Not Found</h1>
        <Link to="/portal/dashboard">Back To DashBoard</Link>
      </div>
    </div>
  );
};

export { UnauthorizedPage };
