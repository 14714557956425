import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getDataDetails, getProjects } from "../../api/api";
import { Button } from "../../components/Button/button";
import _ from "lodash";

type createProps = {
  closeDialog: any;
  isDialogOpen: boolean;
  selectedID: any;
};

const AddTeamMember = ({
  closeDialog,
  isDialogOpen,
  selectedID,
}: createProps) => {
  const [openDialog, setOpenDialog] = useState(true);
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [imageLoader, setImageLoader] = useState(false);
  const [orgDetails, setOrgDetails] = useState<any>(null);
  const user_id: any = Cookies.get("id");
  const user_type: any = Cookies.get("user_type");
  const [onBoard, setOnBoard] = useState<any>({
    name: "",
    designation: "",
    email: "",
    password: "",
    imageData: "",
    project: "",
  });

  useEffect(() => {
    async function getOrgDetails() {
      try {
        const action = "getOrganizationDetails";
        const data = await getDataDetails(action, user_id);
        // If organization details are available, populate the form fields
        if (data.length) {
          setOrgDetails(data);
        }
      } catch (error: any) {
        console.error("Error during login process:", error.message);
      }
    }
  
    if (user_type === "admin") {
      getOrgDetails();
    }
  }, [user_id, user_type]);
  
  
  
 
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // Create a FileReader to read the image file
      const fileName = file.name;
      setSelectedFileName(fileName);
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target) {
          const base64Image = event.target.result;
          // Update the formData state with the Base64 image data
          setOnBoard({
            ...onBoard,
            imageData: base64Image,
          });
        }
      };

      // Read the image file as Base64
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    console.log("selectedID", selectedID);
    async function getMemberDetails() {
      try {
        const action = "getMemberDetails";
        const data = await getDataDetails(action, user_id, selectedID);
        // If organization details are available, populate the form fields
        if (data.length) {
          const { name, designation, email, password, profile_pic, project } =
            data[0];
          setOnBoard({
            name: name || "",
            designation: designation || "",
            email: email || "",
            password: password || "",
            imageData: profile_pic || "",
            project: project || "",
          });
        }
      } catch (error: any) {
        console.error("Error during login process:", error.message);
      }
    }

    getMemberDetails(); // Call the async function
  }, [selectedID]);

  const handleAddOnboard = async (e: any) => {
    e.preventDefault();
    setIsbtnLoad(true);
    let raw: any = []
    if(selectedID){
      raw =    JSON.stringify({
        action: "editMember",
        member_name: onBoard.name,
        member_designation: onBoard.designation,
        member_pic: onBoard.imageData,
        admin_id: parseInt(user_id),
        member_id: selectedID
      })
    }else {
      raw =    JSON.stringify({
        action: "addMember",
        email: onBoard.email,
        PASSWORD: onBoard.password,
        member_name: onBoard.name,
        member_designation: onBoard.designation,
        member_pic: onBoard.imageData,
        admin_id: parseInt(user_id),
        user_type: "member",
        org_id: parseInt(orgDetails[0]?.id),
      })
    }
 
    try {
      const response = await fetch("https://makeithost.com/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
 
        body: raw
      
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setIsbtnLoad(false);
        setTimeout(() => {
          sendDataToParent();
        }, 1500);
      }
      const result = await response.json();
      setErrorValidation(result.result);
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  const sendDataToParent = () => {
    const data = "Hello from the Child!";
    closeDialog(data);
  };




  return (
    <>
      {isDialogOpen ? (
        <div
          style={{ backgroundColor: "#00000073" }}
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
        >
          <div className="relative p-4 w-6/12 max-h-full">
            <div className="relative bg-white rounded-lg shadow p-4">
              <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
                  Add Team Member
                </h3>
                <i
                  className="fa-solid fa-close mx-2 cursor-pointer"
                  onClick={closeDialog}
                ></i>
              </div>
              {errorValidation ? (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">{errorValidation}</strong>
                </div>
              ) : null}

              <div className=" py-4 px-10">
                <div className="my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
                  >
                    Name<small className="text-rose-400 mb-2">*</small>
                  </label>
                  <div className="mt-0 w-12/12">
                    <input
                      type="text"
                      value={onBoard.name}
                      onChange={(e) =>
                        setOnBoard({ ...onBoard, name: e.target.value })
                      }
                      className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
                  >
                    Designation<small className="text-rose-400 mb-2">*</small>
                  </label>
                  <div className="mt-0 w-12/12">
                    {/* <input
                      type="text"
                      value={onBoard.designation}
                      onChange={(e) =>
                        setOnBoard({ ...onBoard, designation: e.target.value })
                      }
                      className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    /> */}
                    <select
                    value={onBoard.designation}
                    onChange={(e) =>
                      setOnBoard({ ...onBoard, designation: e.target.value })
                    }
                       className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                    >
                      <option value="">Select</option>
                      <option value="Cloud Engineer">Cloud Engineer</option>
                      <option value="Engineer Manager">Engineer Manager</option>
                      <option value="QA Manager">QA Manager</option>
                      <option value="Project Manager">Project Manager</option>
                      <option value="Product Manager">Product Manager</option>
                      <option value="Scrum Master">Scrum Master</option>
                      <option value="Technical Writer">Technical Writer</option>
                    </select>
                  </div>
                </div>
                {
                  !selectedID ?
                  <>
                   <div className="my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
                  >
                    Email<small className="text-rose-400 mb-2">*</small>
                  </label>
                  <div className="mt-0 w-12/12">
                    <input
                      type="email"
                      autoComplete="off"
                      value={onBoard.email}
                      onChange={(e) =>
                        setOnBoard({ ...onBoard, email: e.target.value })
                      }
                      className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-900 w-12/12 leading-9 pe-2"
                  >
                    Password<small className="text-rose-400 mb-2">*</small>
                  </label>
                  <div className="mt-0 w-12/12">
                    <input
                      type="password"
                      autoComplete="off"
                      value={onBoard.password}
                      onChange={(e) =>
                        setOnBoard({ ...onBoard, password: e.target.value })
                      }
                      className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                  </>
                  : null
                }
               
                <p className="text-base mt-6">Profile Pic</p>
                <div className="flex">
                  {onBoard?.imageData ? (
                    <img
                      className="w-14 h-14 my-2"
                      src={onBoard?.imageData.toString()}
                    />
                  ) : (
                    <img
                      className="w-14 h-14 my-2 rounded"
                      src={require("../../assets/defaultWorkspaceImg.png")}
                    />
                  )}
                  <div className="mt-4 ml-2">
                    <input
                      type="file"
                      name="imageData"
                      onChange={handleFileChange}
                    />
                    <p className=" w-full text-xs text-slate-400 mt-1">
                      .png, .jpg, .gif files up to 8MB. Recommended size is 256
                      / 256.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <footer className="border-t mt-2 pt-3 flex justify-end dark:border-gray-100 px-6">
                <button onClick={closeDialog} className="mx-4 text-sky-700">
                  Cancel
                </button>
                <Button
                  children="Save"
                  type="button"
                  className="items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleAddOnboard}
                  isButtonLoaded={isbtnLoad}
                />
              </footer>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { AddTeamMember };
