import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getData } from "../../api/api.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.min.css";
import { Button } from "../../components/Button/button";
import defaultPic from "../../assets/defaultWorkspaceImg.png";
import { AddProject } from "./AddProjects";
import _ from "lodash";
import { convertToUSFormat } from "../../utils/Utils";
import { useProjectsList } from "../../data/data";

const Projects = () => {
  const [projectsList, setProjectsList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isbtnLoad, setIsbtnLoad] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [deletedID, setDeletedID] = useState<any>(null);
  const [selectedID, setSelectedID] = useState(null);
  const user_id = Cookies.get("id");
  const { data, isLoading, isFetching, refetch } = useProjectsList(
    "getProjects",
    user_id
  );



  const columnDefs = [
    { headerName: "Name", field: "name", flex: 1 },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      cellRenderer: (p: any) => {
        const date = convertToUSFormat(p.data.created_at);
        return <>{date}</>;
      },
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      flex: 1,
      cellRenderer: (p: any) => {
        const date = convertToUSFormat(p.data.updated_at);
        return <>{date}</>;
      },
    },
    {
      headerName: "Actions",
      field: "",
      flex: 1,
      cellRenderer: (p: any) => {
        const id = p.data.id;
        return (
          <>
            <i
              onClick={() => handleEdit(id)}
              className="fa-regular fa-pen-to-square cursor-pointer"
            ></i>
            <i
              onClick={() => handleDeleteOpen(id)}
              className="fa-solid fa-trash ms-2 cursor-pointer"
            ></i>
          </>
        );
      },
    },
  ];
  function handleEdit(id: any) {
    setIsDialogOpen(true);
    setSelectedID(id);
  }
  function handleDeleteOpen(id: any) {
    setDeleteOpen(true);
    setDeletedID(id);
    setErrorValidation(null);
  }
  const handleDelete = async () => {
    setIsbtnLoad(true);
    try {
      const response = await fetch("https://makeithost.com/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteProject",
          id: deletedID,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setIsbtnLoad(false);
        setTimeout(() => {
          setDeleteOpen(false);
        }, 600);
        refetch();
      }
      const result = await response.json();
      setErrorValidation(result.result);
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };
  function handleAdd() {
    setIsDialogOpen(true);
    setSelectedID(null);
  }

  const handleChildData = (data: any) => {
    setIsDialogOpen((current) => !current);
    refetch();
  };
  const foundItem: any = _.filter(data, { id: deletedID });
 
  return (
    <div>
      <div className="flex justify-between my-3">
        <h2 className="text-lg capitalize">
          <span className="font-bold text-xl">Projects</span>
        </h2>
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-md bg-indigo-600 px-3  h-8 font-semibold  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleAdd}
          children={"Add"}
        />
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 500,
          zIndex: 0,
          fontFamily: "ui-sans-serif, system-ui, sans-serif",
          fontSize: "12px",
        }}
      >
        {isFetching ? (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-indigo-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : data?.length ? (
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data || []}
            rowHeight={35}
            pagination={true}
            paginationPageSize={20}
          />
        ) : (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            No data Available
          </div>
        )}
      </div>
      {isDialogOpen ? (
        <AddProject
          isDialogOpen={isDialogOpen}
          closeDialog={handleChildData}
          selectedID={selectedID}
        />
      ) : null}
      {deleteOpen ? (
        <div
          style={{ backgroundColor: "#00000073" }}
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-screen h-screen"
        >
          <div className="relative p-4 w-4/12 max-h-full">
            <div className="relative bg-white rounded-lg shadow p-4">
              <div className="flex items-center justify-between border-b rounded-t dark:border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray leading-10">
                  Delete Project
                </h3>
                <i
                  className="fa-solid fa-close mx-2 cursor-pointer"
                  onClick={() => setDeleteOpen(false)}
                ></i>
              </div>
              {errorValidation ? (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">{errorValidation}</strong>
                </div>
              ) : null}
              <div className="py-4">
                Are you sure you want to delete this project ?{" "}
                <span className="font-bold	text-indigo-700">
                  {foundItem[0]?.name}
                </span>
              </div>
              <footer className="border-t mt-2 pt-3 flex justify-end dark:border-gray-100 px-6">
                <button
                  onClick={() => setDeleteOpen(false)}
                  className="mx-4 text-sky-700"
                >
                  Cancel
                </button>
                <Button
                  children={"Delete"}
                  type="button"
                  className="items-center flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => handleDelete()}
                  isButtonLoaded={isbtnLoad}
                  
                />
              </footer>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export { Projects };
