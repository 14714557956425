import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getMembersList, getProjects } from "../../api/api.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.min.css";
import { Button } from "../../components/Button/button";
import { AddTeamMember } from "../team/Add";
import defaultPic from "../../assets/defaultWorkspaceImg.png";
import { useTaskList, useTeamList } from "../../data/data";
import { useMemberDetails } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { AddTask } from "../addTask";

const TaskList = () => {
  const [teamList, setTeamList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const user_id = Cookies.get("id");
  const user_type = Cookies.get("user_type");
  const { memberDetails, error } = useMemberDetails();
  const orgID =
    user_type === "admin"
      ? memberDetails && memberDetails[0]?.id
      : memberDetails && memberDetails[0]?.org_id;
  const { data, isLoading, isFetching, refetch } = useTaskList(
    "getTasks",
    orgID
  );

  const columnDefs = [
    {
      headerName: "Key",
      field: "task_id",
      width: 100,
    },
    {
      headerName: "Summary",
      field: "task_name",
      flex: 1,
      cellRenderer: (p: any) => {
        return (
          <Link
            to={`/portal/taskDetails/${p?.data?.task_id}`}
            className="mx-1 font-medium text-sky-500 dark:text-indigo-500 capitalize"
            //target="_blank"
            rel={p?.data?.task_name}
          >
            {p?.data?.task_name}
          </Link>
        );
      },
    },
    {
      headerName: "Assignee",
      field: "assignee",
      width: 150,
      resizable: true,
      cellRenderer: (p: any) => {
        return (
          <span className="capitalize">
            {p?.data?.assignee ? p?.data?.assignee : "--"}
          </span>
        );
      },
    },
    {
      headerName: "Engineering Manager",
      field: "engineeringManager",
      width: 150,
      resizable: true,
    },
    {
      headerName: "Fix Version",
      field: "fixVersion",
      width: 150,
      resizable: false,
    },
    { headerName: "Sprint", field: "sprint", width: 150, resizable: false },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      resizable: false,
      cellRenderer: (p: any) => {
        let statusColor;

        switch (p.data.status.toLowerCase()) {
          case "open":
            statusColor = "#3498db"; // Light Blue
            break;
          case "inprogress":
            statusColor = "#e67e22"; // Orange
            break;
          case "codereview":
            statusColor = "#9b59b6"; // Purple
            break;
          case "codecomplete":
            statusColor = "#2ecc71"; // Green
            break;
          case "resolve":
            statusColor = "#34495e"; // Dark Gray
            break;
          default:
            statusColor = ""; // Default color, you can set it to a default color if needed
        }

        return (
          <span
            className="py-1 rounded capitalize"
            style={{ color: statusColor }}
          >
            {p.data.status}
          </span>
        );
      },
    },
  ];

  function handleEdit(id: any) {
    setIsDialogOpen(true);
    setSelectedID(id);
  }
  console.log(selectedID);

  function handleAdd() {
    setIsDialogOpen(true);
  }

  const handleChildData = (data: any) => {
    setIsDialogOpen((current) => !current);
    setSelectedID(null);
  };

  return (
    <div>
      <div className="flex justify-between my-3">
        <h2 className="text-lg capitalize">
          <span className="font-bold text-xl">Task List</span>
        </h2>
        <Button
          type={"submit"}
          className={
            "items-center flex justify-center rounded-md bg-indigo-600 px-3  h-8 font-semibold  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          }
          onClick={handleAdd}
          children={"Add"}
        />
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 500,
          zIndex: 0,
          fontFamily: "ui-sans-serif, system-ui, sans-serif",
          fontSize: "12px",
        }}
      >
        {isFetching ? (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-indigo-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : data?.length ? (
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data || []}
            rowHeight={35}
            pagination={true}
            paginationPageSize={20}
          />
        ) : (
          <div className="flex space-x-2 justify-center items-center bg-white  dark:bg-indigo-800 h-full dark:invert">
            No data Available
          </div>
        )}
      </div>
      {isDialogOpen ? <AddTask closeDialog={handleChildData} /> : null}
    </div>
  );
};

export { TaskList };
